<template>
    <div>
      <b-card>
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >

        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in rooms"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <!-- Item Name -->
          <b-col md="2">
            <b-form-group
              label="Room Code"
              label-for="item-code"
            >
            <b-form-input
              id="item-name"
              type="text"
              v-model="item.code"
              disabled

            />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Room Name"
              label-for="item-name"
            >
              <b-form-input
                id="item-name"
                type="text"
                v-model="item.name"
                placeholder="Room Name"

              />
            </b-form-group>
          </b-col>

          <!-- Cost -->


          <!-- Quantity -->
          <b-col md="2">
            <b-form-group
              label="Color"
              label-for="color"
            >
              <v-select
                :options="colorOptions"
                v-model="item.colorq"
                :clearable="false"
                :append-to-body="true"
                :filterable="false"
              />
            </b-form-group>
          </b-col>

          <!-- Profession -->
          <b-col
            lg="2"
            md="1"
          >
            <b-form-group
              label="Enabled"
              label-for="enabled"
            >
              <b-form-checkbox
                :checked="item.enabled"
                v-model="item.enabled"
                class="custom-control-success mt-1"
                name="check-button"
                switch
            >
                <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
                </span>
            </b-form-checkbox>
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col
            lg="4"
            md="4"
            class="mb-50 text-right"
          >
         <!--   <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2 mr-2"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>Delete</span>
            </b-button>
-->

          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>

      </b-form>

    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add New</span>
    </b-button>

    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="success"
      @click="submit"
      class="ml-5"
    >
      <feather-icon
        icon="CheckIcon"
        class="mr-25"
      />
      <span>Submit</span>
    </b-button>
  </b-card>
  </div>
</template>

<script>
import { BForm, BFormGroup, BFormInput, BRow, BCol, BButton,BCard,BFormCheckbox } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import { heightTransition } from '@core/mixins/ui/transition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
export default {
  components: {
    BCardCode,
    BForm,
    BCard,
    BFormCheckbox,
    vSelect,
    BRow,
    BCol,
    ToastificationContent,
    BButton,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
        form : {
            center_id : this.$store.getters['center/currentCenterId'],
            rooms : []
        },
        rooms: [],
        centerOptions : [
            {
                id:1,
                label:'Studio 1'
            },
            {
                id:2,
                label:'Studio 2'
            },
        ],
        colorOptions : [
            {
                id:1,
                label:'yellow'
            },
            {
                id:2,
                label:'green'
            },
            {
                id:3,
                label:'red'
            },
        ],
      items: [
    {
        id: 1,
        name: 'aa',
        selected: 'male',
        selected1: 'designer',
        prevHeight: 0,
      },
      {
        id: 2,
        name: 'vv',
        selected: 'male',
        selected1: 'designer',
        prevHeight: 0,
      }
      ],
      nextTodoId: 3,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    this.get_color()
    this.get_room()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
      submit() {
          this.form.rooms = this.rooms
          this.$http.post(process.env.VUE_APP_API_BASE+"/rooms/edit/",this.form)
        .then(res => {
            if (res.data.result === true) {
              this.get_room()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Success',
                    icon: 'CheckIcon',
                    variant: 'success',
                    text: res.data.message,
                  },
                })
            }
        })
      },
      get_room() {
      this.$http.get(process.env.VUE_APP_API_BASE+`/rooms/?center_id=`+ this.$store.getters['center/currentCenterId'])
        .then(res => {
          this.rooms = res.data.rooms
          this.nextTodoId = res.data.rooms.length + 1
           this.initTrHeight()
        })
    },
    get_color() {
      this.$http.get(process.env.VUE_APP_API_BASE+`/colors/`)
        .then(res => {
          this.colorOptions = res.data
        })
    },
    repeateAgain() {
      this.rooms.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
